import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import axios from "axios";
import { NotificationManager } from 'react-notifications';
import "./styles.css";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import ImageUploading from 'react-images-uploading';
import LoadingOverlay from 'react-loading-overlay';

const containerStyle = { width: '100%', height: '275px' };

const center = { lat: 41.876610, lng: -87.624573 };

const ContactHelperComponent = (props) => {
    const [ state, setState ] = useState({
        telephone: "",
        message: "",
        email: "",
        isLoading: false,
        firstName: "",
        imagesUploaded: [],
        images: [],
        lastName: "",
        subject: "",
        typeOfInquiry: null
    });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
    })
    
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        console.log("window.google.maps.", window.google.maps);
        // map.fitBounds(bounds);
        // setMap(map)
    }, [])
    
    const onUnmount = React.useCallback(function callback(map) {
        // setMap(null)
    }, [])
    
    
    console.log("state", state);

    const handleSubmissionContact = () => {
        console.log("handleSubmissionContact clicked/ran...");

        setState(prevState => ({ ...prevState, isLoading: true }));

        if (typeof state.images !== "undefined" && state.images.length > 0) {
            handleImageUpload().then(async passedValues => {
                // Do something with passedValues
                console.log('Passed Values images --- :', passedValues);
    
                NotificationManager.success('Successfully uploaded/updated image(s)!', 'Uploaded image(s) successfully.', 3000);
    
                const {
                    telephone,
                    message,
                    email,
                    firstName,
                    lastName,
                    subject,
                    typeOfInquiry,
                    imagesUploaded
                } = state;
        
                const config = {
                    telephone,
                    message,
                    email,
                    firstName,
                    lastName,
                    subject,
                    imagesUploaded,
                    typeOfInquiry
                }
        
                axios.post(`${process.env.REACT_APP_BASE_URL}/contact`, config).then((res) => {
                    if (res.data.message === "Successfully sent your message!") {
                        console.log("successsss");
        
                        NotificationManager.success('Successfully sent the desired contact message!', 'Successfully sent message.', 3000);
                    } else {
                        console.log("err");
        
                        NotificationManager.warning('Error occurred trying to send your message/email!', 'Error sending occurred...', 3000);
                    }
                })
            })
        } else {
            const {
                telephone,
                message,
                email,
                firstName,
                lastName,
                subject,
                typeOfInquiry
            } = state;
    
            const config = {
                telephone,
                message,
                email,
                firstName,
                lastName,
                subject,
                imagesUploaded: [],
                typeOfInquiry
            }
    
            axios.post(`${process.env.REACT_APP_BASE_URL}/contact`, config).then((res) => {
                if (res.data.message === "Successfully sent your message!") {
                    console.log("successsss");

                    setState(prevState => ({ ...prevState, isLoading: false, telephone: "",
                        message: "",
                        email: "",
                        isLoading: false,
                        firstName: "",
                        imagesUploaded: [],
                        images: [],
                        lastName: "",
                        subject: "",
                        typeOfInquiry: null
                    }));
    
                    NotificationManager.success('Successfully sent the desired contact message!', 'Successfully sent message.', 3000);
                } else {
                    console.log("err");
    
                    setState(prevState => ({ ...prevState, isLoading: false, telephone: "",
                        message: "",
                        email: "",
                        isLoading: false,
                        firstName: "",
                        imagesUploaded: [],
                        images: [],
                        lastName: "",
                        subject: "",
                        typeOfInquiry: null 
                    }));
                    
                    NotificationManager.warning('Error occurred trying to send your message/email!', 'Error sending occurred...', 3000);
                }
            })
        }
    };

    console.log("state.imagesUploaded", state.imagesUploaded);

    const handleImageUpload = () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
    
            for (let index = 0; index < state.images.length; index++) {
                const image = state.images[index];
                formData.append(`files`, image.file);
    
                if ((state.images.length - 1) === index) {
                    try {
                        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload/misc/file/wo/saving/array`, formData);
                        
                        if (res.data.message === "Successfully uploaded images!") {
                            console.log(res.data);
        
                            const { passedValues } = res.data;
    
                            setState(prevState => ({ ...prevState, imagesUploaded: passedValues, isLoading: false }));
        
                            resolve(passedValues);
                        } else {
                            console.log("Err", res.data);
                            
                            NotificationManager.warning('Error attempting to upload your image/photo!', 'Try the action again - image upload failed.', 3000);
        
                            setState(prevState => ({ ...prevState, isLoading: false }));
                            reject("Image upload failed");
                        }
                    } catch (err) {
                        console.log(err.message);
                        setState(prevState => ({ ...prevState, isLoading: false }));
                        reject(err);
                    }
                }
            }
        });
    };

    return (
        <Fragment>
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}  
            <NavigationUniversalHelper />
            <div className="small-content" />
            {/*Contact Us Card*/}
            <div className="container">
                <div className="contact__wrapper shadow-lg mt-n9">
                    <div className="row no-gutters">
                    <div className="col-lg-7 col-sm-12 col-xs-12 contact-form__wrapper p-5 order-lg-1">
                        <div className="contact-content">
                            <form id="contact-form">
                                <h2>Contact Us</h2>
                                <p>Call or Text us at:  <a href="tel:7035815023">(703)-581-5023</a></p>
                                <select onChange={(e) => setState(prevState => ({ ...prevState, typeOfInquiry: e.target.value }))} id="subject" name="subject">
                                    <option disabled="" selected="">
                                    TYPE OF INQUIRY
                                    </option>
                                    <option value="consignment">CONSIGNMENT</option>
                                    <option value="general">GENERAL INQUIRY</option>
                                    <option value="support">CUSTUMER SUPPORT</option>
                                    <option value="partnership">PARTNERSHIP OPPORTUNITIES</option>
                                </select>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={state.email}
                                    onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                                    placeholder="YOUR EMAIL"
                                    required=""
                                />
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={state.subject}
                                    onChange={(e) => setState(prevState => ({ ...prevState, subject: e.target.value }))}
                                    placeholder="MESSAGE SUBJECT"
                                    required=""
                                />
                                <input
                                    type="text"
                                    id="fname"
                                    name="fname"
                                    value={state.firstName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, firstName: e.target.value }))}
                                    placeholder="FIRST NAME"
                                    required=""
                                />
                                <input
                                    type="text"
                                    id="lname"
                                    name="lname"
                                    value={state.lastName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, lastName: e.target.value }))}
                                    placeholder="LAST NAME"
                                    required=""
                                />
                                <input
                                    type="text"
                                    id="tel"
                                    name="tel"
                                    value={state.telephone}
                                    onChange={(e) => setState(prevState => ({ ...prevState, telephone: e.target.value }))}
                                    placeholder="TELEPHONE"
                                    required=""
                                />
                                <textarea
                                    type="text"
                                    id="message"
                                    value={state.message}
                                    onChange={(e) => setState(prevState => ({ ...prevState, message: e.target.value }))}
                                    name="message"
                                    placeholder="INQUIRY"
                                    required=""
                                    defaultValue={""}
                                />
                                <ImageUploading
                                        multiple={true}
                                        value={state.images}
                                        onChange={(images) => setState(prevState => ({ ...prevState, images }))}
                                        maxNumber={6}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            onImageUpload,
                                            onImageRemove,
                                            isDragging,
                                            imageList,
                                            onImageUpdate,
                                            dragProps
                                        }) => (
                                            // write your building UI
                                            <Fragment>
                                                <div style={{ marginTop: "22.25px" }} className="upload__image-wrapper">
                                                    <div style={isDragging ? { color: 'red' } : undefined} {...dragProps} onClick={() => {
                                                        onImageUpload();
                                                        
                                                        setState(prevState => ({ ...prevState, images: imageList }))
                                                    }}><img className="imageupload" src={require("../../assets/icons/dragdrop.jpg")} /></div>
                                                    &nbsp;
                                                </div>
                                                <div style={{ marginTop: "22.25px" }} className="row">
                                                    {imageList.map((image, index) => (
                                                        <div key={index} className="image-item col-md-2 col-lg-2 col-sm-12" style={{ marginRight: 27.5, marginBottom: 15 }}>
                                                            <img src={image['data_url']} alt="" width="100" />
                                                            <div className="image-item__btn-wrapper">
                                                                <button onClick={(e) => {
                                                                    e.preventDefault();

                                                                    onImageRemove(index);
                                                                }}>Remove</button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Fragment>
                                        )}
                                    </ImageUploading>
                                <div className="btn">
                                    <a style={{ color: "#fff" }} onClick={() => handleSubmissionContact()}>Contact Us</a>
                                </div>
                                {/* // <p id="address">
                                //     Chicago Violin Auction LLC. ~ 410 S Michigan Ave Chicago, IL 60605, United States
                                // </p> */}
                                {/* <p id="phones">T: +1 777 777 7777 F: +1 777 777 7777</p> */}
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 contact-info__wrapper gradient-brand-color p-5 order-lg-2">
                        <h3 style={{ fontSize: "2rem" }} className="color--white mb-5">Get in Touch</h3>
                        <ul className="contact-info__list list-style--none position-relative z-index-101">
                        <li className="mb-4 pl-4 color--white-two">
                            <span style={{ marginRight: 10 }}>
                                <img src={require("../../assets/icons/envolope.png")} style={{ maxWidth: 32.5, maxHeight: 32.5, height: 32.5, width: 32.5 }} />
                            </span>{" "}
                            info@chicagoviolinauction.com
                        </li>
                        <li className="mb-4 pl-4 color--white-two">
                            <span style={{ marginRight: 10 }}>
                                <img src={require("../../assets/icons/phone.png")} style={{ maxWidth: 32.5, maxHeight: 32.5, height: 32.5, width: 32.5 }} />
                            </span>{" "}
                            <a href="tel:7035815023" style={{ color: "white" }}>(703)-581-5023</a>
                        </li>
                        <li className="mb-4 pl-4 color--white-two">
                            <span style={{ marginRight: 10 }}>
                                <img src={require("../../assets/icons/marker.png")} style={{ maxWidth: 32.5, maxHeight: 32.5, height: 32.5, width: 32.5 }} />
                            </span>{" "}
                            Chicago Violin Auction LLC.
                            <br /> 410 S Michigan Ave
                            <br /> Chicago, IL 60605
                            <div className="mt-3">
                            <a
                                href="https://www.google.com/maps"
                                target="_blank"
                                className="text-link link--right-icon text-white"
                            >
                                Get directions <i className="link__icon fa fa-directions" />
                            </a>
                            </div>
                        </li>
                        <li className="mb-4 pl-4 color--white-two">
                            {isLoaded ? <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <Marker position={center} />
                            </GoogleMap> : null}
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default ContactHelperComponent;
